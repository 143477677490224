import createCalculator from './calculator';
import createPredefinedCalculator from './predefinedCalculator';
import { INITIAL_VALUE } from '../constants';

function CalculatorSwitcher() {
    let isTurnedPreviosly = null;
    let calculateUsual = null;
    let calculatePredefined = null;
    let latestState = null;
    return {
      getCalculator: (isTurned) => {
        latestState = isTurned
        if(isTurnedPreviosly !== isTurned) {
          isTurnedPreviosly = isTurned;
          calculateUsual = createCalculator(INITIAL_VALUE);
          calculatePredefined = createPredefinedCalculator();
        };
        return isTurned
          ? calculatePredefined
          : calculateUsual;
      },
      reset: () => {
        calculateUsual = createCalculator(INITIAL_VALUE);
        calculatePredefined = createPredefinedCalculator();
        return latestState
          ? calculatePredefined
          : calculateUsual;
      }
    }
  }

export default CalculatorSwitcher;
