let cb = () => {};

const handleDeviceorientation = (event) => {
    const { alpha, beta, gamma } = event;
    cb({ alpha, beta, gamma });
}

const subscribe = () => {
    if (window.DeviceOrientationEvent) {
        window.addEventListener("deviceorientation", handleDeviceorientation, true);
    }
}

const onDeviceOrientation = (callback) => {
    cb = callback;
    subscribe();
}

export default onDeviceOrientation;