import React from 'react';
import scenarios from './scenarios';
import { TouchHandler } from '../../services/touchHandler';
import './TouchScreen.css'

const touchHandler = new TouchHandler({ scenarios });

const TouchScreen = ({ children }) => {
    return <div 
        className="touch-screen"
        onTouchMove={touchHandler.handleTouchMove}
        onTouchStart={touchHandler.handleTouchStart}
        onTouchEnd={touchHandler.handleTouchEnd}
    >
        {children}
    </div>
}

export default TouchScreen;