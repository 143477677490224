import React from 'react';
import App from '../App';
import OrientationProvider from '../OrientationProvider';
import TouchScreen from '../TouchScreen';

export default () => (
    <OrientationProvider>
        <TouchScreen>
            <App />
        </TouchScreen>
    </OrientationProvider>
)