export const createTouchWatcher = (initTouch) => {
    const takeX = touch => touch.pageX;
    const takeY = touch => touch.pageY;
    let active = true;
    let x = takeX(initTouch);
    let y = takeY(initTouch);
    return {
        getId: () => initTouch.identifier,
        matchToTouch: (touch) => {
            return touch.identifier === initTouch.identifier
        },
        stop: () => active = false,
        active: () => active,
        setNewPosition: (touch) => {
            x = takeX(touch);
            y = takeY(touch);
        },
        getPosition: () => ({x, y}),
    };
}