import {
    DEFAULT_PREDEFINED_VALUE,
    PREDEFINED_VALUE_STORAGE_NAME,
} from '../constants'

export const getPredefinedValue = () => {
    const value = localStorage.getItem(PREDEFINED_VALUE_STORAGE_NAME);
    return value || DEFAULT_PREDEFINED_VALUE;
}

export const setPredefinedValue = (value) => {
    localStorage.setItem(PREDEFINED_VALUE_STORAGE_NAME, value);
}