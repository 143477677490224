import React, { createContext, useState, useContext } from 'react';
import onTurnOver from '../../services/onTurnOver';

export const OrientationContext = createContext(false);

export const useOrientation = () => useContext(OrientationContext);

const OrientationProvider = ({ children }) => {
    const [state, setState] = useState(false);
    onTurnOver(({ isTurnedOver }) => setState(isTurnedOver));
    return (
        <OrientationContext.Provider value={state}>
            { children }
        </OrientationContext.Provider>
    )
}

export default OrientationProvider;