export const add = (a, b) => a + b;
export const minus = (a, b) => a - b;
export const multiply = (a, b) => a * b;
export const divide = (a, b) => a / b;

export const operatorsMap = {
    add,
    '+': add,
    minus,
    '-': minus,
    multiply,
    '*': multiply,
    divide,
    '/': divide,
}
