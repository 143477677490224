import { operatorsMap } from './operators';

export const MAX_LENGTH = 9;
export const INFINITY = (1/0).toString();
const isResult = (value) => value === 'result' || value === '=';
const isOperator = (value) => '+-*/'.includes(value);
const isNumber = (value) => '0123456789.'.includes(value);
const isString = (value) => typeof value === 'string';
const transformEvent = (event) => {
    if(!isString(event)) {
        return event;
    }
    if(isNumber(event)) {
        return { value: event }
    }
    if(isOperator(event)) {
        return { operator: event }
    }
    if(isResult(event)) {
        return { operator: 'result' }
    }
    return undefined;
}

let getCurrentValueCallback = () => null;

const calculator = (initValue) => {
    let firstOperand = null;
    let buffer = null;
    let storedOperator = null;

    const getNewCalculator = (event) => {
        event = transformEvent(event);
        if(event === undefined) {
            storedOperator = null;
            firstOperand = null;
            buffer = null;
            return initValue;
        }
        const { value, operator } = event;
        if(value) {
            if(buffer && buffer.length >= MAX_LENGTH) {
                return buffer;
            }
            if(buffer && value === '.' && buffer.includes('.')) {
                return buffer;
            }
            const valueToDisplay = buffer === null 
                ? (value === '.' ? '0.' : value)
                : ((buffer === '0' && value !== '.') ? value : `${buffer}${value}`);
            buffer = valueToDisplay;
            return `${valueToDisplay}`
        }
        if(operator) {
            if(storedOperator !== null && firstOperand !== null && buffer !== null) {
                const action = operatorsMap[storedOperator]
                const result = action(Number(firstOperand), Number(buffer))
                if(`${result}`.split('.')[0].length > MAX_LENGTH) {
                    return INFINITY;
                }           
                firstOperand = result;
                storedOperator = isResult(operator) ? null : operator;
            } else {
                firstOperand = buffer !== null ? buffer : firstOperand;
                storedOperator = operator;
            }
            buffer = null;
            return `${firstOperand}`;
        }
        return initValue
    }

    return (event) => {
        const currnetValue = getNewCalculator(event);
        getCurrentValueCallback = () => currnetValue;
        return currnetValue;
    };
}

export default calculator;

export const getCurrentValue = () => {
    return getCurrentValueCallback();
}