import React from 'react';
import classnames from 'classnames'
import Button from '../Button'
import './Keyboard.css'

export default ({ className, onClick = () => {} }) => (
    <div 
        onClick={(e) => onClick(e.target.dataset.button)}
        className={classnames(className, "keyboard")}
    >
        <Button text={'АC'}/>
        <Button value={'/'} text={'/'}/>
        <Button value={'*'} text={'*'}/>
        <Button value={'-'} text={'-'}/>
        <Button value={7} text={7}/>
        <Button value={8} text={8}/>
        <Button value={9} text={9}/>
        <Button className="keyboard__button--plus" value={'+'} text={'+'}/>
        <Button value={4} text={4}/>
        <Button value={5} text={5}/>
        <Button value={6} text={6}/>
        <Button value={1} text={1}/>
        <Button value={2} text={2}/>
        <Button value={3} text={3}/>
        <Button className="keyboard__button--equal" value={'='} text={'='}/>
        <Button className="keyboard__button--zero" value={0} text={0}/>
        <Button value={'.'} text={'.'}/>
    </div>
);