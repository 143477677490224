import { useContext, useState, useCallback } from 'react';
import { OrientationContext } from '../../components/OrientationProvider';
import CalculatorSwitcher from '../calculatorSwitcher';
import { onChangeDisplaydValue } from '../valueToDisplay';
import { INITIAL_VALUE } from '../../constants';

const calculatorSwitcher = new CalculatorSwitcher();

const useCalculator = () => {
    const [displayValue, setDisplayValue] = useState(INITIAL_VALUE);
    const isTurned = useContext(OrientationContext);
    let calculate = calculatorSwitcher.getCalculator(isTurned);
    onChangeDisplaydValue((value) => {
        calculate = calculatorSwitcher.reset();
        setDisplayValue(value);
    })
    const handleAction = useCallback((value) => {
        setDisplayValue(calculate(value))
      }, [calculate]);
    return [ displayValue, handleAction ];
};

export default useCalculator;