import React from 'react';
import classnames from 'classnames';
import { useOrientation } from '../OrientationProvider'
import './Display.css';

export default ({ className, text='' }) => {
    const isTurnedOver = useOrientation();
    return (
        <div 
            className={classnames(
                className,
                'display',
                {'display--magic': isTurnedOver },
                {'display--no-magic': !isTurnedOver },
            )}
        >
            <span className="display-text">{text}</span>
        </div>
    )
};