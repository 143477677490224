import onDeviceOrientationChange from './onDeviceOrientationChange';
import isTurned from './isTurned';

let isTurnedOver = false;
let turnedOverHandler;

onDeviceOrientationChange((event) => {
    const newState = isTurned(event);
    if(newState === isTurnedOver) {
        return;
    }
    isTurnedOver = newState;
    turnedOverHandler({ isTurnedOver });
})

export default (cb) => {
    if(!turnedOverHandler) {
        cb({ isTurnedOver })
    }
    turnedOverHandler = cb;
}