import React from 'react';
import Keyboard from '../Keyboard';
import Display from '../Display';
import useCalculator from '../../services/hooks/useCalculator';

import './App.css';

const App = () => {
  const [ displayValue, handleAction ] = useCalculator();

  return (
    <main className="main calc">
      <Display text={displayValue} className="calc_display" />
      <Keyboard onClick={handleAction} className="calc_keyboard" />
    </main>
  )
};

export default App;
