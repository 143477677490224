import Box from './box';
import { getCurrentValue } from '../../services/calculator';
import { setValueToDisplay } from '../../services/valueToDisplay';
import { setPredefinedValue, getPredefinedValue } from '../../services/predefinedValue'
import { INITIAL_VALUE } from '../../constants';

window.document.body.style.width = '100vw';
window.document.body.style.height = '100vh';
const { width, height } = window.document.body.getBoundingClientRect();

function Path({
    startBox,
    endBox,
}) {
    let _isFinished = false;
    let watcherId = null;
    return {
        isFinished: () => _isFinished,
        reset: () => {
            _isFinished = false;
            watcherId = null;
        },
        check: (watcher) => {
            if(!watcherId && startBox.belongsToBox(watcher.getPosition())) {
                watcherId = watcher.getId();
            }
            if(watcherId === watcher.getId() && endBox.belongsToBox(watcher.getPosition())) {
                _isFinished = true;
            }
        },
    }
}

const createScenario = ({
    path1,
    path2,
    generateAction = () => {},
}) => {
    const paths = [path1, path2];

    return {
        reset: () => paths.forEach(p => p.reset()),
        isSuccess: () => paths.every(p => p.isFinished()),
        checkWatchers: (watchers) => {
            if(watchers.length !== paths.length) {
                return paths.forEach(p => p.reset());
            }
            paths.forEach(p => {
                watchers.forEach(p.check)
            });
        },
        generateAction,
    }
}

const rows = 4;

const topLeftBox = new Box({
    minX: 0,
    maxX: width/3,
    minY: 0,
    maxY: height/rows,
});

const topRightBox = new Box({
    minX: width*2/3,
    maxX: width,
    minY: 0,
    maxY: height/rows,
});

const bottomLeftBox = new Box({
    minX: 0,
    maxX: width/3,
    minY: height*(rows-1)/rows,
    maxY: height,
});

const bottomRightBox = new Box({
    minX: width*2/3,
    maxX: width,
    minY: height*(rows-1)/rows,
    maxY: height,
});

const scenarios = [
    createScenario({
        path1: new Path({
            startBox: topLeftBox,
            endBox: topRightBox,
        }),
        path2: new Path({
            startBox: bottomLeftBox,
            endBox: bottomRightBox,
        }),
        generateAction: () => { 
            const value = getCurrentValue()
            setPredefinedValue(value);
            setValueToDisplay(INITIAL_VALUE);
        }
    }),
    createScenario({
        path1: new Path({
            startBox: topRightBox,
            endBox: topLeftBox,
        }),
        path2: new Path({
            startBox: bottomRightBox,
            endBox: bottomLeftBox,
        }),
        generateAction: () => {
            const value = getPredefinedValue()
            setValueToDisplay(value);
        }
    }),
]

export default scenarios;