
import { createTouchWatcher } from './touchWatcher'

const transformEvent = (handler) => {
    return (e) => handler({ 
        touches: Array.from(e.touches)
    });
}
export function TouchHandler({ scenarios, setState = () => {} }) {
    let touchWatchers = [];
    
    const handleTouchStart = ({ touches }) => {
        const newWatchers = touches
            .filter(touch => !touchWatchers.find(watcher => watcher.matchToTouch(touch)))
            .map(createTouchWatcher)
        touchWatchers = [...touchWatchers, ...newWatchers];
        setState(touchWatchers);
    };
    
    const handleTouchMove = ({ touches }) => { 
        touchWatchers.forEach(watcher => {
            const touch = touches.find(watcher.matchToTouch);
            watcher.setNewPosition(touch);
        })

        scenarios.forEach( scenario => {
            scenario.checkWatchers(touchWatchers);
            if(scenario.isSuccess()) {
                scenario.generateAction();
                scenario.reset();
            }
        });

        setState([...touchWatchers]);
    };

    const handleTouchEnd = ({ touches }) => {
        touchWatchers = touchWatchers
            .filter(watcher => touches.find(touch => watcher.matchToTouch(touch)))
            .filter(watcher => watcher.active);
        setState(touchWatchers);
        scenarios.forEach(scenario => scenario.reset());
    };

    return {
        handleTouchStart: transformEvent(handleTouchStart),
        handleTouchMove: transformEvent(handleTouchMove),
        handleTouchEnd: transformEvent(handleTouchEnd),
    }
};