import { getPredefinedValue } from './predefinedValue'

export default () => {
    let restOfValue = getPredefinedValue().split('');
    let valueToReturn = '';
    return () => {
        const digitToAdd = restOfValue.shift() || '';
        valueToReturn = valueToReturn + digitToAdd;
        return valueToReturn;
    }
}
